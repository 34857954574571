<template>
    <div style="height: 100%">

        <!--<CForm @submit.prevent="getResultsByConditions()">
            <CRow>

            </CRow>
        </CForm>-->

        <CRow alignVertical="center" class="mb-4">
            <!--<CCol lg="3">
                <label>Filter:</label><input class="sample1-filter-input">
            </CCol>-->
            <CCol lg="3">
                <model-list-select
                        class="mb-0"
                        v-model="conditions.time_id"
                    :list="times"
                   option-value="value"
                   option-text="label"
                   placeholder="Выберите режим"
                    @input="setDataTypes($event)"
                   >
                </model-list-select>
            </CCol>
            <CCol lg="3">
                <CInput
                    class="mb-0"
                    type="date"
                    name="action"
                    v-model="conditions.created_date"
                    required
                    @input="getResults"
                />
            </CCol>


            <CCol lg="3">
                <download-excel
                        ref="grid"
                        :fetch="getResults"
                        :fields="fields_for_excel"
                        :stringifyLongNum="true"
                        :escapeCsv="true"
                        :meta="json_meta"
                        class="btn btn-outline-primary"
                        type="scv"
                        :name="exportName"
                >
                    Скачать отчет
                </download-excel>
            </CCol>
        </CRow>

        <c-grid
                ref="grid"
                :data="tData"
                :frozen-col-count="1"
                :theme="userTheme">
            <!-- define checkbox -->
            <c-grid-column
                    field="name"
                    width="20%"
                    min-width="350"
                    caption="Нитка"
            />
            <c-grid-column-group
                    v-for="(elem, index_g) in times_dataTypes" v-bind:key="index_g"
                    :field="elem.name"
                    :caption="elem.name"
                    :header-style="{textAlign:'center'}">

                <c-grid-column
                    v-for="(elem_1, index) in elem.types" v-bind:key="index"
                    :field="elem_1.id + '_' + elem.id"
                    :caption="elem_1.name"
                    :column-style="{textAlign: 'center'}"
                />
            </c-grid-column-group>
        </c-grid>
    </div>
</template>

<script>
  import axios from 'axios';


  import * as cGridAll from 'vue-cheetah-grid';
  import Vue from 'vue';
  import {ModelListSelect} from "vue-search-select";
  import 'vue-search-select/dist/VueSearchSelect.css'

  const materialDesignTheme = cGridAll.cheetahGrid.themes.MATERIAL_DESIGN;
  Vue.use(materialDesignTheme)


  import JsonExcel from "vue-json-excel";

  Vue.component("downloadExcel", JsonExcel);


  let tData = [];

  const formatYmd = (date) => {
    return date.toISOString().slice(0, 10)
  };

  let tzoffset = (new Date()).getTimezoneOffset() * 60000;
  let today = formatYmd(new Date(Date.now() - tzoffset));

  let obj = {},
    temp_tdata_yesterday = [];

  export default {
    name: 'Results2',
    data: function () {
      return {
        exportName: "filename.xlsx",
        tData,
        temp_tdata: [],
        conditions:
          {
            created_date: today,
            time_id: '',
            time_name: '',
          },

        times_dataTypes: [],
        dataTypes: [],
        times: [],
        timeDatas: [],
        apiDatas: [],
        fields: [
          {
            field: "line_order",
            hidden: true,
            sortable: true,
            type: 'number',
          },
          {
            field: "name",
            label: "Нитка",
          }
        ],
        fields_for_excel: {
          "Нитка": "name",
        },
        json_meta: [
          [{
            " key ": " charset ",
            " value ": " utf- 8 "
          },{
            " key ": " name ",
            " value ": " file.xls "
          },
          ]
        ],
        userTheme: materialDesignTheme.extends({
          defaultBgColor({col, row, grid}) {
            const record = tData[row - grid.frozenRowCount];

            if (row < grid.frozenRowCount) {
              return null;
            }
            if (record !== undefined && record.is_region !== undefined) {
              return "#3c4b64"
            }
            if (record !== undefined && record.name === "Итого") {
              return '#DEF';
            } else {
              if (col !== 0 && col % 5 === 0) {
                return "#f1f1f1"
              }
            }
          },
          color({col, row, grid}) {
            const record = tData[row - grid.frozenRowCount];
            if (row < grid.frozenRowCount) {
              return null;
            }
            if (record !== undefined && record.is_region !== undefined) {
              return "#fff"
            }
          },
          borderColor({col, row, grid}) {
            const record = tData[row - grid.frozenRowCount];
            if (row < grid.frozenRowCount) {
              return null;
            }
            if (record !== undefined && record.is_region !== undefined) {
              return "#3c4b64"
            }
            return "#ccc"
          }
        }),
        form: {
          action: 'create_data',
          operator_id: "13",
          datas: [],
          time_id: "",
          line_id: "",
          created_date: "",
        },
        data_type_id: '',
      }
    },
    methods: {
      setDataTypes: function (e) {
        let param_name,
            param_value;
          this.$refs.times_dataTypes = [];
          this.times_dataTypes = [{
              id: this.timeDatas[e].id,
              name: this.timeDatas[e].name,
              types: this.timeDatas[e].types
          }]
        this.fields_for_excel = {};
        this.timeDatas[e].types.forEach((el)=>{
          param_name = el.name;
          param_value = el.id + "_" + el.time_id;


          this.fields_for_excel["Нитка"] = "name";
          this.fields_for_excel[param_name] = param_value;

        })

          this.$refs.grid.invalidate();

          if (this.conditions.created_date !== undefined) {
            this.getResults();
          }

          this.conditions.time_name = this.timeDatas[e].name;

        },
      getResults: function () {

        let startDate = this.conditions.created_date;
        let endDate = new Date(startDate);
        let aDayBefore = new Date(startDate);

        endDate.setDate(endDate.getDate(startDate) + 1);
        endDate = formatYmd(endDate);

        aDayBefore.setDate(aDayBefore.getDate(startDate) - 1);
        aDayBefore = formatYmd(aDayBefore);


        temp_tdata_yesterday = JSON.parse(JSON.stringify([]))


        axios
          .get(`${axios.defaults.baseURL}?action=get_all_lines_w_region`)
          .then((response) => {
            this.temp_tdata = JSON.parse(JSON.stringify([]))
            this.temp_tdata = JSON.parse(JSON.stringify(response.data))
            // this.temp_tdata = response.data;

            // Закидываем все нитки в тДата
            this.tData = JSON.parse(JSON.stringify([]));
            this.tData = JSON.parse(JSON.stringify(Object.values(this.temp_tdata)));

            axios
              .get(`${axios.defaults.baseURL}?action=get_results_by_date_time_id_r&created_date=${startDate}&time_id=${this.conditions.time_id}`)
              .then((results_response) => {
                let temp_count_array = [{}];


                let regions = [];
                let region_id;

                // для подсчёта по Q час (id типа данных = 9)
                // создаём массив чтобы складывать в него данные, выбранные по региону, типу данных и режиму
                // тип данных всегда = 9
                // 9_(time_id)_(region_id)

                let total_count = {};
                let temp_time = "";


                Object.values(results_response.data).forEach((el, index) => {

                  // получаем "адрес" целевой ячейки внутри строки
                  let a_c;
                  // получаем значение для целевой ячейки
                  let b;
                  // создаём и заполняем объект {адрес_ячейки : значение_ячейки}
                  let f = {};

                  let r_id;
                  // получаем "адрес" целевой ячейки внутри строки
                  a_c = el.target;
                  // получаем значение для целевой ячейки
                  b = el.datavalue;
                  // создаём и заполняем объект {адрес_ячейки : значение_ячейки}
                  f[a_c] = b;

                  this.temp_tdata[el.line_id + "_id"] = Object.assign({}, this.temp_tdata[el.line_id + "_id"], f);

                  if (temp_time === "") {
                    temp_time = el.time_id;
                  }
                  temp_time = el.time_id;

                  if (el.is_countable !== "1") {
                    region_id = el.region_id;
                  }

                  r_id = el.region_id + "_" + temp_time;
                  temp_count_array[r_id] = temp_count_array[r_id] !== undefined ? temp_count_array[r_id] : [];
                  total_count[r_id] = total_count[r_id] !== undefined ? total_count[r_id] : [];

                  if (temp_time === el.time_id && el.datavalue !== "" && el.datavalue !== "0"  && el.datavalue !== "00") {
                    if (el.data_type_id === "9") {
                      temp_count_array[r_id].push(parseInt(el.datavalue))
                    } else if (el.data_type_id === "11") {
                      total_count[r_id].push(parseInt(el.datavalue))
                    }
                  }


                  // this.temp_tdata[el.split("-")[0] + "_id"] = Object.assign({},this.temp_tdata[el.split("-")[0] + "_id"], f);

                  if (this.temp_tdata[el.line_id + "_id"] !== undefined) {
                    this.temp_tdata[el.region_id + "_id_r_count"] = Object.assign({}, this.temp_tdata[el.region_id + "_id_r_count"], {forecast: temp_count_array[el.region_id]});
                  }


                  if (!regions.includes(region_id)) {
                    regions.push(region_id)
                  }
                })

                let r_total = "total_r_count";
                let temp_c_arr_reduce;
                let temp_total_arr_reduce;
                let all_total_c = 0;
                let all_total = {};

                this.times.forEach((t_id) => {
                  regions.forEach((r_id) => {
                    if (temp_count_array[r_id+"_"+t_id.time_id] !== undefined && temp_count_array[r_id+"_"+t_id.time_id].length > 0) {
                      temp_c_arr_reduce = temp_count_array[r_id + "_" + t_id.time_id].reduce((a,b)=>a+b,0)
                    } else {
                      temp_c_arr_reduce = "0"
                    }
                    if (total_count[r_id+"_"+t_id.time_id] !== undefined && total_count[r_id+"_"+t_id.time_id].length > 0) {
                      temp_total_arr_reduce = total_count[r_id+"_"+t_id.time_id].reduce((a,b)=>a+b,0)
                    } else {
                      temp_total_arr_reduce = "0"
                    }

                    let count_target;

                    if (t_id.time_id === "43") {
                      count_target = "11_" + t_id.time_id

                      let total_target_obj = {};

                      total_target_obj[count_target] = temp_total_arr_reduce;

                      Object.assign(this.temp_tdata[r_id + "_id_r_count"], total_target_obj)
                      all_total_c = all_total_c + parseFloat(temp_total_arr_reduce);
                    } else {
                      count_target = "9_" + t_id.time_id

                      let count_target_obj = {};
                      count_target_obj[count_target] = temp_c_arr_reduce;

                      Object.assign(this.temp_tdata[r_id + "_id_r_count"], count_target_obj)
                      all_total_c = all_total_c + parseFloat(temp_c_arr_reduce);
                    }
                  })

                  let count_target;

                  if (all_total_c === 0) {
                    all_total_c = "0"
                  }

                  if (t_id.time_id === "43") {
                    count_target = "11_" + t_id.time_id;

                    all_total[count_target] = all_total_c;
                    Object.assign(this.temp_tdata[r_total], all_total)
                  } else {
                    count_target = "9_" + t_id.time_id;

                    all_total[count_target] = all_total_c;

                    Object.assign(this.temp_tdata[r_total], all_total)
                  }

                  all_total_c = 0;

                })

                tData = JSON.parse(JSON.stringify(Object.values(this.temp_tdata)));
                this.tData = JSON.parse(JSON.stringify(tData));
                // this.$refs.grid.invalidate()
              })
          })

        this.exportName = startDate + "__" + this.conditions.time_name + ".xlsx"

        return this.tData;
      },
      createData(in_data) {

        let bodyFormData = new FormData();

        bodyFormData.append('action', "create_data_from_table");
        bodyFormData.append('operator_id', this.form.operator_id);
        bodyFormData.append('line_id', in_data.record.id);
        bodyFormData.append('time_id', in_data.field.split("_")[1]);
        bodyFormData.append('data_type_id', in_data.field.split("_")[0]);
        bodyFormData.append('s_value', in_data.value);

        axios({
          method: "post",
          url: `${axios.defaults.baseURL}`,
          data: bodyFormData
        })
          .then((r) => {
            console.log(r)
          })
          .catch((error) => {
            console.error(error)
          }).finally(() => {});
      },
    },
    mounted() {
        axios
            .get(`${axios.defaults.baseURL}?action=get_times_w_datatypes`)
            .then((response) => {
                response.data.map((el) => {
                  if (el.id !== "39") {

                    this.times.push({
                      time_id: el.id,
                      id: el.id,
                      value: el.id,
                      label: el.name,
                    });
                    this.timeDatas[el.id] = {
                      id: el.id,
                      name: el.name,
                      types: el.datatypes
                    };
                  }
                });
            })

      this.getResults();
      this.$refs.grid.$on("changed-value",(e)=> {
        // this.createData(e)
        console.log(e)
      })
    },

    components: {
      ModelListSelect,
      ...cGridAll
    }
  }
</script>
<style>
    .container-fluid {
        height: 100%;
    }
    .c-main {
        padding-top: 1rem;
    }
</style>
